import React, { useEffect, useRef } from "react";
import LevelCard from "./levelCard/LevelCard";
import { Mission } from "../services/missionService";
// import { logMessage } from "../services/logger"; // Importez logMessage
import { useMissionContext } from "../context/MissionContext";

interface MissionListProps {
  missions: Mission[];
}

const MissionList: React.FC<MissionListProps> = ({ missions }) => {
  const { currentLevelId } = useMissionContext();
  const cardRefs = useRef<Map<string, HTMLDivElement>>(new Map());
  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (currentLevelId && containerRef.current) {
      const cardElement = cardRefs.current.get(currentLevelId);
      const containerElement = containerRef.current;

      if (cardElement) {
        const cardRect = cardElement.getBoundingClientRect();
        const containerRect = containerElement.getBoundingClientRect();

        // Calcul de la position relative de la carte
        const cardOffset =
          cardRect.top - // Position verticale de la carte
          containerRect.top;

        // Calcul du scroll
        const newScrollTop =
          containerElement.scrollTop + // Position actuelle de défilement dans le conteneur.
          cardOffset;

        // logMessage(
        //   `newScrollTop: ${newScrollTop}, containerElement.scrollTop: ${containerElement.scrollTop}, cardRect.height: ${cardOffset}`
        // );

        containerElement.scrollTo({
          top: newScrollTop,
          behavior: "smooth",
        });
      }
    }
  }, [currentLevelId]);

  return (
    <div
      ref={containerRef}
      className="overflow-y-auto scrollbar scrollbar-thin scrollbar-thumb-accent scrollbar-track-neutral backdrop-blur-sm flex flex-wrap gap-[32px] p-[8px] justify-center max-h-[calc(100%-60px)] pb-[500px]"
    >
      {missions.map((mission) => (
        <div
          key={mission.id}
          className="flex justify-center w-[calc(100%-32px)] sm:w-[calc(50%-32px)] lg:w-[calc(33.33%-32px)] xl:w-[calc(25%-32px)]"
          ref={(el) => {
            if (el) {
              cardRefs.current.set(mission.id, el);
            } else {
              cardRefs.current.delete(mission.id);
            }
          }}
        >
          <LevelCard mission={mission} />
        </div>
      ))}
    </div>
  );
};

export default MissionList;
