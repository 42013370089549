import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
} from "react";
import { useHeightCalculator } from "./HeightManagerContext";

interface DrawerContextProps {
  currentThreshold: number;
  setThreshold: (threshold: "closed" | "small" | "medium" | "max") => void;
  getClosestThreshold: (y: number, velocity: number) => number;
  thresholds: Record<"closed" | "small" | "medium" | "max", number>;
}

export const DrawerContext = createContext<DrawerContextProps | undefined>(
  undefined
);

export const DrawerProvider: React.FC<{
  children: React.ReactNode;
  viewportHeight: number;
}> = ({ children, viewportHeight }) => {
  const { totalHeight = 200, headerHeight = 50 } = useHeightCalculator();

  const thresholds = useMemo(() => {
    const safeTotalHeight = totalHeight || 0; // Valeur par défaut
    const safeHeaderHeight = headerHeight || 0; // Valeur par défaut

    return {
      closed: viewportHeight,
      small: viewportHeight - safeHeaderHeight,
      medium: viewportHeight - safeTotalHeight,
      max: 0,
    };
  }, [viewportHeight, totalHeight, headerHeight]);

  const [currentThreshold, setCurrentThreshold] = useState(thresholds.medium);

  const setThreshold = useCallback(
    (threshold: "closed" | "small" | "medium" | "max") => {
      if (thresholds[threshold] !== undefined) {
        setCurrentThreshold(thresholds[threshold]);
      } else {
        console.warn(`Invalid threshold: ${threshold}`);
      }
    },
    [thresholds]
  );

  const getClosestThreshold = useCallback(
    (y: number, velocity: number) => {
      const thresholdEntries = Object.entries(thresholds) as [
        "closed" | "small" | "medium" | "max",
        number
      ][];

      if (velocity !== 0) {
        const isMovingUp = velocity < 0;
        const sortedThresholds = isMovingUp
          ? thresholdEntries.sort((a, b) => b[1] - a[1])
          : thresholdEntries.sort((a, b) => a[1] - b[1]);

        for (const [, value] of sortedThresholds) {
          if ((isMovingUp && value <= y) || (!isMovingUp && value >= y)) {
            return value;
          }
        }
      }

      const closest = thresholdEntries.reduce(
        (prev, [, value]) => {
          const distance = Math.abs(y - value);
          return distance < prev.distance
            ? { threshold: value, distance }
            : prev;
        },
        { threshold: thresholds.medium, distance: Infinity }
      );

      return closest.threshold;
    },
    [thresholds]
  );

  const value = useMemo(
    () => ({
      currentThreshold,
      setThreshold,
      getClosestThreshold,
      thresholds,
    }),
    [currentThreshold, setThreshold, getClosestThreshold, thresholds]
  );

  return (
    <DrawerContext.Provider value={value}>{children}</DrawerContext.Provider>
  );
};

export const useDrawerContext = (): DrawerContextProps => {
  const context = useContext(DrawerContext);
  if (!context) {
    throw new Error("useDrawerContext must be used within a DrawerProvider");
  }
  return context;
};
