import { useEffect, useRef } from "react";
import { useHeightCalculator } from "../context/HeightManagerContext";

const useRegisterHeight = (id: string, isActive: boolean = true) => {
  const { registerComponentHeight, unregisterComponentHeight } =
    useHeightCalculator();
  const ref = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (isActive && ref.current) {
      const measureHeight = () => {
        const rect = ref.current!.getBoundingClientRect();
        const styles = window.getComputedStyle(ref.current!);
        const margin =
          parseFloat(styles.marginTop) + parseFloat(styles.marginBottom);

        return rect.height + margin;
      };

      const height = measureHeight();
      registerComponentHeight(id, height);

      window.addEventListener("resize", measureHeight);

      return () => {
        unregisterComponentHeight(id);
      };
    }
  }, [id, isActive, registerComponentHeight, unregisterComponentHeight]);

  return ref; // Retourne uniquement le `ref`
};

export default useRegisterHeight;
