import { useState, useEffect } from "react";

const useDeviceDetection = () => {
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [isDesktop, setIsDesktop] = useState(false);
  const [supportsVisualViewport, setSupportsVisualViewport] = useState(false);
  const [viewportWidth, setViewportWidth] = useState(0);
  const [viewportHeight, setViewportHeight] = useState(0);

  useEffect(() => {
    const updateDeviceInfo = () => {
      // Vérifie si visualViewport est disponible
      const visualViewport = window.visualViewport;

      const width = visualViewport?.width || window.innerWidth;
      const height = visualViewport?.height || window.innerHeight;
      setViewportWidth(width);
      setViewportHeight(height);
      setSupportsVisualViewport(!!visualViewport);

      // Dimensions typiques des smartphones (portrait)
      const isPhone = width <= 768 && height <= 1024; // Exemple : iPhone, Android phones
      const isTabletDevice = width > 768 && width <= 1024 && height <= 1366; // Exemple : iPads, Galaxy Tabs

      // Détermine les catégories de l'appareil
      setIsMobile(isPhone);
      setIsTablet(isTabletDevice);
      setIsDesktop(!isPhone && !isTabletDevice);
    };

    updateDeviceInfo(); // Mise à jour initiale
    window.addEventListener("resize", updateDeviceInfo);

    return () => {
      window.removeEventListener("resize", updateDeviceInfo);
    };
  }, []);

  return {
    isMobile,
    isTablet,
    isDesktop,
    supportsVisualViewport,
    viewportWidth,
    viewportHeight,
  };
};

export default useDeviceDetection;
