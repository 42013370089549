import React, { ReactNode } from "react";
import { motion, PanInfo, useMotionValue } from "framer-motion";
import { useDrawerContext } from "../../context/DrawerContext";

const Drawer: React.FC<{ children: ReactNode; viewportHeight: number }> = ({
  children,
  viewportHeight,
}) => {
  const { currentThreshold, setThreshold, getClosestThreshold, thresholds } =
    useDrawerContext();
  const drawerY = useMotionValue(currentThreshold);

  const handleDragEnd = (
    event: MouseEvent | TouchEvent | PointerEvent,
    info: PanInfo
  ) => {
    const currentY = drawerY.get();
    const targetThreshold = getClosestThreshold(currentY, info.velocity.y);
    setThreshold(
      Object.keys(thresholds).find(
        (key) => thresholds[key as keyof typeof thresholds] === targetThreshold
      ) as "closed" | "small" | "medium" | "max"
    );
  };

  return (
    <motion.div
      className="fixed bottom-0 left-0 right-0"
      style={{
        height: viewportHeight,
        y: drawerY,
      }}
      drag="y"
      dragConstraints={{ top: thresholds.max, bottom: thresholds.closed }}
      onDragEnd={handleDragEnd}
      animate={{ y: currentThreshold }}
      transition={{
        type: "spring",
        stiffness: 300,
        damping: 40,
      }}
    >
      <div className="overflow-hidden" style={{ height: viewportHeight }}>
        {children}
      </div>
    </motion.div>
  );
};

export default Drawer;
