import { useMissionContext } from "../../context/MissionContext";
import useRegisterHeight from "../../hooks/useRegisterHeight";
import { Mission } from "../../services/missionService";
import CardDivider from "../share/CardDivider";
import "../styles/gradients.css";

interface LevelCardProps {
  mission: Mission;
}

const LevelCard: React.FC<LevelCardProps> = ({ mission }) => {
  const { currentLevelId, setCurrentLevelId } = useMissionContext();
  const isActive = currentLevelId === mission.id;

  const ref = useRegisterHeight("levelCard", isActive); // Inscription conditionnelle

  const handleCardClick = () => {
    setCurrentLevelId(mission.id);
  };

  return (
    <div
      ref={ref}
      onClick={handleCardClick}
      className={`relative max-w-[430px] h-auto mx-auto bg-black overflow-hidden gradient-border transform transition-transform duration-300 ease-in-out ${
        isActive ? "bg-secondary " : ""
      } cursor-pointer`}
    >
      <div className="relative flex flex-col p-4 gap-4">
        <div>
          <h2 className="font-bold text-3xl text-white text-left uppercase">
            {mission.title}
          </h2>
        </div>
        <CardDivider color="border-secondary" />
        <div className="flex justify-center">
          <img
            className="w-full h-48 object-cover"
            src={mission.image}
            alt={mission.title}
          />
        </div>
        <p className="text-2xl text-white text-left">
          {mission.shortDescription}
        </p>
      </div>
    </div>
  );
};
export default LevelCard;
