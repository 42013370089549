import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
  useMemo,
} from "react";
import {
  Country,
  fetchCountries,
  fetchFilteredMissions,
  fetchTypes,
  Mission,
  Type,
} from "../services/missionService";

// Définir le type pour le contexte des missions
interface MissionContextType {
  filteredMissions: Mission[];
  filterMissions: (country: string, type: string) => void;
  countries: Country[];
  types: Type[];
  currentLevelId: string | null; // ID de la carte active
  setCurrentLevelId: React.Dispatch<React.SetStateAction<string | null>>; // Setter
}

// Créer le contexte avec des valeurs par défaut
const MissionContext = createContext<MissionContextType | undefined>(undefined);

// Fournisseur du contexte
export const MissionProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [filteredMissions, setFilteredMissions] = useState<Mission[]>([]);
  const [countries, setCountries] = useState<Country[]>([]);
  const [types, setTypes] = useState<Type[]>([]);
  const [currentLevelId, setCurrentLevelId] = useState<string | null>(null); // Ajouté ici

  useEffect(() => {
    const loadInitialData = async () => {
      try {
        const missionsData = await fetchFilteredMissions("", "");
        setFilteredMissions(missionsData);

        const countriesData = await fetchCountries();
        setCountries(countriesData);

        const typesData = await fetchTypes();
        setTypes(typesData);
      } catch (error) {
        console.error(
          "Erreur lors du chargement des données initiales :",
          error
        );
      }
    };

    loadInitialData();
  }, []);

  const memoizedFilteredMissions = useMemo(
    () => filteredMissions,
    [filteredMissions]
  );

  const filterMissions = async (country: string, type: string) => {
    try {
      const filtered = await fetchFilteredMissions(country, type);
      if (filtered.length > 0) {
        setFilteredMissions(filtered);

        const updatedCountries = await fetchCountries(type);
        setCountries(updatedCountries);

        const updatedTypes = await fetchTypes(country);
        setTypes(updatedTypes);
      } else {
        console.warn("Aucune mission trouvée.");
      }
    } catch (error) {
      console.error("Erreur lors du filtrage des missions :", error);
    }
  };

  return (
    <MissionContext.Provider
      value={{
        filteredMissions: memoizedFilteredMissions,
        filterMissions,
        countries,
        types,
        currentLevelId, // Exposé ici
        setCurrentLevelId, // Exposé ici
      }}
    >
      {children}
    </MissionContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte des missions
export const useMissionContext = () => {
  const context = useContext(MissionContext);
  if (context === undefined) {
    throw new Error("useMissionContext must be used within a MissionProvider");
  }
  return context;
};
