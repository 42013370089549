import React, { useEffect, useRef, useCallback } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import { useMissionContext } from "../../context/MissionContext";
import { initializeYinYangApp } from "@micheeeeel/yinyangapp";
import { useDrawerContext } from "../../context/DrawerContext";

mapboxgl.accessToken =
  "pk.eyJ1IjoidG9taWNoIiwiYSI6ImNqbTR2em8zbzB0OXozcnA3YTVtbGthYmEifQ.HSUnomleAXPO2kEwm1Stfw"; // Remplacez par votre token Mapbox

const LevelsMap: React.FC = () => {
  const { setThreshold } = useDrawerContext();
  const { currentLevelId, setCurrentLevelId } = useMissionContext();

  const { filteredMissions } = useMissionContext();
  const mapContainerRef = useRef<HTMLDivElement>(null);
  const mapRef = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef<Map<string, HTMLDivElement>>(new Map());

  const svgCache = useRef<Map<string, string>>(new Map());

  // Ajout des styles globaux pour les marqueurs
  useEffect(() => {
    const styleElement = document.createElement("style");
    styleElement.innerHTML = `
        .marker-with-stem::after {
          content: "";
          position: absolute;
          top: 150px; /* Placez le bas de la tige directement au bas du conteneur de l'icône. */
          left: 50%; /* Centrer la tige sous le marqueur */
          transform: translateX(-50%);
          width: 7px; /* Largeur de la tige */
          height: 75px; /* Hauteur de la tige (moitié de la taille du marqueur) */
          background-color: rgba(0, 0, 0, 0.3); /* 30% d'opacité */
          border-radius: 4px; /* Arrondir les bords */
        }
      `;
    document.head.appendChild(styleElement);

    return () => {
      document.head.removeChild(styleElement);
    };
  }, []);

  // Fonction de nettoyage des marqueurs
  const clearMarkers = useCallback(() => {
    markersRef.current.forEach((markerElement) => {
      markerElement.remove();
    });
    markersRef.current.clear();
  }, []);

  // Fonction pour convertir un élément SVG en URL de type data:image/svg+xml
  const generateSvgDataUrl = (svgElement: SVGElement): string => {
    const svgString = new XMLSerializer().serializeToString(svgElement);
    return `data:image/svg+xml;charset=utf-8,${encodeURIComponent(svgString)}`;
  };
  const previousActiveMarkerIdRef = useRef<string | null>(null);

  const updateMarkerStyles = useCallback(() => {
    const previousActiveMarkerId = previousActiveMarkerIdRef.current;

    // Si l'ID actif a changé
    if (currentLevelId !== previousActiveMarkerId) {
      // Mettre à jour le marqueur qui devient inactif
      if (previousActiveMarkerId) {
        const previousMarker = markersRef.current.get(previousActiveMarkerId);
        if (previousMarker) {
          previousMarker.classList.remove(
            "ring-4",
            "ring-secondary",
            "brightness-125",
            "scale-110"
          );
          previousMarker.classList.add("ring-0", "brightness-100", "scale-100");
        }
      }

      // Mettre à jour le nouveau marqueur actif
      const newMarker = markersRef.current.get(currentLevelId!);
      if (newMarker) {
        newMarker.classList.remove("ring-0", "brightness-100", "scale-100");
        newMarker.classList.add(
          "ring-4",
          "ring-secondary",
          "brightness-125",
          "scale-110"
        );
      }

      // Mettre à jour la référence au dernier marqueur actif
      previousActiveMarkerIdRef.current = currentLevelId;
    }
  }, [currentLevelId]);

  // Fonction pour recentrer la carte sur la mission active
  const centerMapOnMission = useCallback(
    (missionId: string | null) => {
      if (!missionId || !mapRef.current) return;

      const mission = filteredMissions.find((m) => m.id === missionId);
      if (mission) {
        const { lat, lng } = mission;

        mapRef.current.flyTo({
          center: [lng, lat],
          essential: true,
          speed: 0.2,
          curve: 1.5,
        });
      }
    },
    [filteredMissions]
  );

  const setThresholdRef = useRef(setThreshold);
  const setCurrentLevelIdRef = useRef(setCurrentLevelId);

  useEffect(() => {
    setThresholdRef.current = setThreshold;
    setCurrentLevelIdRef.current = setCurrentLevelId;
  }, [setThreshold, setCurrentLevelId]);

  const createMarker = useCallback((lat: number, lng: number, id: string) => {
    const markerContainer = document.createElement("div");
    markerContainer.className = `
      cursor-pointer 
      bg-cover 
      bg-no-repeat 
      rounded-full 
      flex 
      items-center 
      justify-center 
      ring-0 
      brightness-100 
      scale-100 
      transition-all 
      duration-300 
      ease-in-out
      marker-with-stem
    `;
    const size = 150;
    markerContainer.style.width = `${size}px`;
    markerContainer.style.height = `${size}px`;

    // Vérifiez si le SVG est déjà dans le cache
    if (!svgCache.current.has(id)) {
      const tempContainer = document.createElement("div");
      tempContainer.id = `temp-svg-container-${id}`;
      document.body.appendChild(tempContainer);

      initializeYinYangApp({
        containerId: tempContainer.id,
        minEdge: size,
        borderAnimationDuration: 15000,
      });

      const svgElement = tempContainer.querySelector(
        "svg"
      ) as SVGElement | null;
      if (svgElement) {
        const svgDataUrl = generateSvgDataUrl(svgElement);
        svgCache.current.set(id, svgDataUrl); // Stocker dans le cache
      }

      document.body.removeChild(tempContainer); // Nettoyer après utilisation
    }

    // Appliquez l'image de fond depuis le cache
    markerContainer.style.backgroundImage = `url('${svgCache.current.get(
      id
    )}')`;

    markerContainer.addEventListener("click", () => {
      // Utiliser les références stables
      setCurrentLevelIdRef.current(id);
      setThresholdRef.current("medium");
    });

    markersRef.current.set(id, markerContainer);

    new mapboxgl.Marker({
      element: markerContainer,
      rotationAlignment: "horizon",
      pitchAlignment: "horizon",
      offset: [0, -size],
    })
      .setLngLat([lng, lat])
      .addTo(mapRef.current!);
  }, []);

  // Fonction pour initialiser la carte
  const initializeMap = useCallback(() => {
    if (mapContainerRef.current && !mapRef.current) {
      mapRef.current = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: "mapbox://styles/mapbox/satellite-v9",
        center: [0, 0],
        zoom: 2,
        projection: "globe",
      });

      mapRef.current.on("style.load", () => {
        mapRef.current?.setFog({
          color: "#ffffff",
          "high-color": "#245cdf",
          "horizon-blend": 0.02,
          range: [0.5, 10],
          "space-color": "#0d0d0d",
          "star-intensity": 0.5,
        });
      });

      mapRef.current.addControl(new mapboxgl.NavigationControl());
    }
  }, []);

  // Fonction pour définir les limites de la carte en fonction des missions
  const setMapBounds = useCallback(
    (missions: { lat: number; lng: number }[]) => {
      const bounds = new mapboxgl.LngLatBounds();
      missions.forEach((mission) => {
        bounds.extend([mission.lng, mission.lat]);
      });

      mapRef.current?.fitBounds(bounds, {
        padding: 100,
        maxZoom: 2,
        duration: 2000,
      });
    },
    []
  );

  useEffect(() => {
    initializeMap();
  }, [initializeMap]);

  useEffect(() => {
    if (mapRef.current) {
      const validMissions = filteredMissions.filter(
        (mission) => !Number.isNaN(mission.lat) && !Number.isNaN(mission.lng)
      );

      if (validMissions.length === 0) return;

      setMapBounds(validMissions);
      clearMarkers();

      validMissions.forEach((mission) => {
        createMarker(mission.lat, mission.lng, mission.id);
      });
    }

    return () => {
      clearMarkers();
    };
  }, [filteredMissions, setMapBounds, clearMarkers, createMarker]);

  useEffect(() => {
    updateMarkerStyles(); // Met à jour les styles des marqueurs
    centerMapOnMission(currentLevelId); // Recentre la carte sur la mission active
  }, [currentLevelId, updateMarkerStyles, centerMapOnMission]);

  return (
    <div className="flex justify-center">
      <div ref={mapContainerRef} className="w-screen h-screen p-8 " />
    </div>
  );
};

export default LevelsMap;
