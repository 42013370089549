import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
  useMemo,
} from "react";

// Types des actions du reducer
type Action =
  | { type: "register"; id: string; height: number }
  | { type: "unregister"; id: string };

// Reducer pour gérer les hauteurs des composants
const heightReducer = (
  state: Map<string, number>,
  action: Action
): Map<string, number> => {
  const newState = new Map(state);

  switch (action.type) {
    case "register":
      newState.set(action.id, action.height);
      break;

    case "unregister":
      newState.delete(action.id);
      break;

    default:
      throw new Error(`Unknown action type: ${action}`);
  }

  return newState;
};

interface HeightManagerContextProps {
  registerComponentHeight: (id: string, height: number) => void;
  unregisterComponentHeight: (id: string) => void;
  getComponentHeightById: (id: string) => number;
  totalHeight: number;
  headerHeight: number;
}

const HeightManagerContext = createContext<
  HeightManagerContextProps | undefined
>(undefined);

export const ComponentHeightProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  // Utilisation de useReducer au lieu de useState
  const [componentHeightsMap, dispatch] = useReducer(
    heightReducer,
    new Map<string, number>()
  );

  // Récupérer la hauteur d'un composant spécifique
  const getComponentHeightById = useCallback(
    (id: string): number => componentHeightsMap.get(id) || 0,
    [componentHeightsMap]
  );

  const totalHeight = useMemo(() => {
    return Array.from(componentHeightsMap.values()).reduce(
      (sum, height) => sum + height,
      0
    );
  }, [componentHeightsMap]);

  const headerHeight = useMemo(() => {
    if (!componentHeightsMap.has("levelCard")) {
      return 0; // Valeur par défaut si "levelCard" n'est pas encore enregistré
    }
    return totalHeight - componentHeightsMap.get("levelCard")!;
  }, [totalHeight, componentHeightsMap]);

  // Méthode pour enregistrer la hauteur d'un composant
  const registerComponentHeight = useCallback((id: string, height: number) => {
    dispatch({ type: "register", id, height });
  }, []);

  // Méthode pour supprimer la hauteur d'un composant
  const unregisterComponentHeight = useCallback((id: string) => {
    dispatch({ type: "unregister", id });
  }, []);

  // Mémorisation des valeurs pour éviter des re-rendus inutiles
  const value = useMemo(
    () => ({
      registerComponentHeight,
      unregisterComponentHeight,
      getComponentHeightById,
      totalHeight,
      headerHeight,
    }),
    [
      registerComponentHeight,
      unregisterComponentHeight,
      getComponentHeightById,
      totalHeight,
      headerHeight,
    ]
  );

  return (
    <HeightManagerContext.Provider value={value}>
      {children}
    </HeightManagerContext.Provider>
  );
};

export const useHeightCalculator = (): HeightManagerContextProps => {
  const context = useContext(HeightManagerContext);
  if (!context) {
    throw new Error(
      "useHeightCalculator must be used within a HeightCalculatorProvider"
    );
  }
  return context;
};
